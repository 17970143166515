.slide-content .col-6 {
  /* background: blue; */
  width: 50%;
  position: relative;
  display: inline-block;
  margin-top: 17px;
}

div[name="current_strategies"] label {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 12px 0px;
  align-items: stretch;
}


div[name="current_strategies"] label span.MuiCheckbox-root {
  margin: 0;
  padding-top: 0;
}

.other-strategy input[type="text"] {
  margin: 0;
  border: 2px solid #808080;
  border-radius: 7px;
  font-size: 17px;
  width: calc(80% - 10px);
  margin-left: 10px;
}

.MuiInputBase-adornedStart {
  border: 1px solid #999;
  border-radius: 7px;
  margin-top: 10px;
}

.MuiInputBase-adornedStart input[type="text"] {
  border: none;
  margin-top: 0;
  outline: none;
  padding-left: 0;
}

span.adornment-dollar {
}

div#marketing-budget {
  height: 100%;
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #000;
}


.Mui-checked {
  color: #ac6ed0 !important;
}

section.slide-content {
  text-align: center;
}

div[name="current_strategies"] .other-strategy {
  flex-direction: column;
  flex-grow: unset;
}

div[name="current_strategies"] .other-strategy input {
  width: fit-content;
}

div[name="current_strategies"] {
  flex-direction: row;
  align-items: normal;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  max-width: 230px;
  text-align: left;
}