header.site-header {
  text-align: center;
  margin-top: 5%;
}

img.header-logo {
  max-height: 100px;
}

#embark-container {
  background: #fff;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  border: 3px solid #582476;
  border-radius: 17px;
  padding: 20px;
}

.form-label {
  font-weight: bold;
}

.form-item {
  margin-top: 40px;
}

.form-item input[type="text"] {
  border: 2px solid #b7b7b7;
  outline: none;
  padding: 10px;
  font-size: 17pt;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 7px;
}

.form-item input[type="text"]::placeholder {
  color: #bfbfbf;
}

div#page-footer {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
}

body {
  background: #491666;
}

section#slide-navigation {
  margin-top: 30px;
  text-align: center;
  position: relative;
}

section#slide-navigation button {
  border: none;
  font-weight: bold;
  text-transform: none;
  font-size: 15pt;
  color: #fff;
}

button#next-button {
  background: #9f3ed6;
}

button#back-button {
  background: #491666;
  position: absolute;
  left: 0;
}

button#finish-button {
  background: #da4af4;
}